

export default {
  'main': [
    {
      name: 'Dashboard User',
      to: '/dashboard',
      icon: 'fas fa-th-large',
      permission: 'ALL',
      notifkey: null
    },
    {
      name: 'Dashboard HR',
      to: '/admin-dashboard',
      icon: 'fas fa-chart-line',
      permission: 'DASHBOARD_EMPLOYEE_GRAPH',
      notifkey: null
    },
    {
      name: 'Kehadiran',
      to: '/attendance',
      key: 'attendance',
      icon: 'fas fa-user-clock',
      permission: 'USER_ATTENDANCE',
      notifkey: null
    },
    {
      name: 'Break',
      to: '/break',
      key: 'break',
      icon: 'fas fa-pause-circle',
      permission: 'USER_BREAK',
      notifkey: null
    }, 
    {
      name: 'Kunjungan',
      to: '/visitation',
      key: 'visitation',
      icon: 'fas fa-map-marked-alt', 
      permission: 'USER_VISITATION',
      notifkey: null
    },

    {
      name: 'Cuti',
      to: '/timeoff',
      icon: 'fas fa-business-time',
      permission: 'USER_TIME_OFF',
      notifkey: null
    },

    {
      name: 'Reimburse',
      to: '/reimburse',
      key: 'reimburse',
      icon: 'fas fa-scroll',
      permission: 'USER_REIMBURSE',
      notifkey: 'MENU_REIMBURSE'
    },

    {
      name: 'Surat Tugas',
      to: '/surattugas',
      key: 'surattugas',
      icon: 'fas fa-envelope-open-text',
      permission: 'USER_ASSIGNMENT_LETTER',
      notifkey: 'MENU_LETTER',
    },

    {
      name: 'Approval',
      to: '/approval',
      icon: 'fas fa-check-double',
      permission: 'USER_APPROVAL',
      notifkey: 'MENU_APPROVAL'
    },

    {
      name: 'Kontak Karyawan',
      to: '/information/contactnumber',
      icon: 'fas fa-address-book',
      permission: 'USER_CONTACT_BOOK',
      notifkey: null
    }, 

    {
      name: 'FAT',
      heading: true,
    },
    {
      name: 'Finance CR',
      to: '/finance/reimburse',
      icon: 'fas fa-file-invoice-dollar',
      permission: 'CASH_REIMBURSE_VIEW',
      notifkey: 'ADMIN_FINANCE_REIMBURSE'
    },

    {
      name: 'Finance CA',
      to: '/finance/cashadvance',
      icon: 'fas fa-cash-register',
      permission: 'CASH_ADVANCE_VIEW',
      notifkey: 'ADMIN_FINANCE_CADVANCE'
    },
    {
      name: 'Payment Voucher',
      to: '/paymentvoucher',
      icon: 'fas fa-file-invoice',
      permission: 'PAYMENT_VOUCHER_VIEW',
      notifkey: 'ADMIN_PAYMENT_VOUCHER'
    },  
    {
      name: 'Petty Cash',
      to: '/petty-cash',
      icon: 'fas fa-money-bill',
      permission: 'PETTYCASH_ACCOUNT_VIEW',
      notifkey: null
    },

    {
      name: 'HRGA',
      heading: true,
    },
    {
      name: 'Karyawan',
      to: '/employee',
      icon: 'fas fa-users-cog',
      permission: 'EMPLOYEE_VIEW',
      notifkey: null
    }, 
    {
      name: 'Kontrak Karyawan',
      to: '/employee/contract',
      icon: 'fas fa-file-signature',
      permission: 'EMPLOYEE_CONTRACT_VIEW',
      notifkey: null
    }, 
    {
      name: 'Surat Peringatan',
      to: '/reprimand',
      icon: 'fas fa-envelope-open-text',
      permission: 'REPRIMAND_LETTER_VIEW',
      notifkey: null
    },  

    {
      name: 'Asset Serah Terima',
      to: '/handover',
      icon: 'fas fa-boxes',
      permission: 'ASSET_HANDOVER_VIEW',
      notifkey: null
    },
    {
      name: 'Manager On Duty',
      to: '/mod/',
      icon: 'fas fa-user-tie',
      permission: 'MANAGER_ON_DUTY_VIEW',  
      notifkey: null
    },
    {
      name: 'Pengumuman',
      to: '/announcement',
      icon: 'fas fa-bullhorn',
      permission: 'ANNOUNCEMENT_VIEW',
      notifkey: null
    },  
    {
      name: 'Rekap Kehadiran',
      to: '/attendance/admin/report',
      icon: 'fas fa-file-contract',
      permission: 'ATTENDANCE_REPORT_VIEW',
      notifkey: null
    },
    {
      name: 'Laporan Kehadiran',
      to: '/attendance/admin/dailyreport',
      icon: 'fas fa-history',  
      permission: 'ATTENDANCE_DAILY_VIEW',
      notifkey: null
    },
    {
      name: 'Laporan Cuti',
      to: '/attendance/admin/timeoffreport',
      icon: 'fas fa-clipboard',
      permission: 'TIME_OFF_REPORT_VIEW',
      notifkey: null
    }, 
    {
      name: 'Laporan Kunjungan',
      to: '/visitation/admin/report',
      icon: 'fas fa-map', 
      permission: 'VISITATION_REPORT_VIEW',
      notifkey: null
    },
    {
      name: 'Laporan Break',
      to: '/break/admin/breakreport',
      icon: 'fas fa-file-alt',   
      permission: 'BREAK_REPORT_VIEW',
      notifkey: null
    },  
    {
      name: 'Laporan Surat Tugas',
      to: '/surattugas/manage',
      icon: 'fas fa-mail-bulk',
      permission: 'ASSIGNMENT_LETTER_REPORT_VIEW',
      notifkey: null
    },  
    {
      name: 'Laporan Ganti Shift',
      to: '/exchangeshift/report',
      icon: 'fas fa-exchange-alt',   
      permission: 'TIME_SLOT_CHANGE_REPORT_VIEW',
      notifkey: null
    },  
    {
      name: 'Laporan Lembur',
      to: '/overtime/report',
      icon: 'fas fa-hourglass-half',   
      permission: 'OVERTIME_REPORT_VIEW',
      notifkey: null
    },  
    {
      name: 'Laporan Pengajuan Keterlambatan',
      to: '/attendancerequest/report',
      icon: 'fas fa-clock',   
      permission: 'ATTENDANCE_REQUEST_VIEW',
      notifkey: null
    }, 
    {
      name: 'Set. Shift',
      to: '/attendance/admin/shift',
      icon: 'fas fa-calendar-week', 
      permission: 'SHIFT_SETTING',
      notifkey: null
    },  
    {
      name: 'Set. Cuti',
      to: '/cuti/admin/report',
      icon: 'fas fa-briefcase', 
      permission: 'TIME_OFF_SETTING',
      notifkey: null
    },  
    {
      name: 'Konfig. Pengupahan',
      to: '/payroll/setting',
      icon: 'fas fa-money-check', 
      permission: 'PAYROLL_CONFIGURATION',
      notifkey: null
    },  
    
    {
      name: 'Daftar Upah',
      to: '/payroll/list',
      icon: 'fas fa-money-check-alt', 
      permission: 'PAYROLL_GENERATE_VIEW',
      notifkey: null
    },  
    {
      name: 'Pinjaman',
      to: '/loan',
      icon: 'fas fa-credit-card', 
      permission: 'LOAN_VIEW',
      notifkey: null
    },   
    {
      name: 'Operasional',
      heading: true,
    },   
    {
      name: 'Asset',
      to: '/asset',
      icon: 'fas fa-archive',
      permission: 'ASSET_VIEW',
      notifkey: null
    },
    {
      name: 'Asset Maintenance',
      to: '/maintenance',
      icon: 'fas fa-tools',
      permission: 'ASSET_MAINTENANCE_VIEW',
      notifkey: null
    },
    {
      name: 'Vendor',
      to: '/vendor',
      icon: 'fas fa-warehouse',
      permission: 'VENDOR_VIEW',
      notifkey: null
    },   
    {
      name: 'Pengaturan',
      heading: true
    },
    {
      name: 'Set. Perusahaan',
      to: '/information/company',
      icon: 'fas fa-building',
      permission: 'COMPANY_SETTING',
      notifkey: null
    },
    {
      name: 'Set. Kategori',
      to: '/information/category',
      icon: 'fas fa-cogs',
      permission: 'CATEGORY_SETTING',
      notifkey: null
    },
    {
      name: 'Set. Kode Shift',
      to: '/information/shift',
      icon: 'fas fa-table',
      permission: 'TIME_SLOT_SETTING_VIEW',
      notifkey: null
    },
    {
      name: 'Set. Divisi & Akses',
      to: '/information/division',
      icon: 'fas fa-tasks',
      permission: 'DIVISION_SETTING',
      notifkey: null
    },
    {
      name: 'Set. Approval',
      to: '/information/settingapproval',
      icon: 'fas fa-project-diagram',
      permission: 'APPROVAL_SETTING',
      notifkey: null
    }, 
    {
      name: 'Set. Lokasi Kehadiran',
      to: '/company/location',
      icon: 'fas fa-map',
      permission: 'ATTENDANCE_LOCATION_SETTING',
      notifkey: null
    },
    {
      name: 'Set. Tipe Cuti',
      to: '/company/timeoff-type',
      icon: 'fas fa-file-alt',
      permission: 'TIME_OFF_TYPE_SETTING',
      notifkey: null
    }, 

    {
      name: 'Super',
      heading: true
    },
    {
      name: 'Message History',
      to: '/messagehistory',
      icon: 'fas fa-mail-bulk',
      permission: 'MESSAGE_HISTORY_VIEW',
      notifkey: null
    },

    {
      name: 'Set. Param',
      to: '/company/setting',
      icon: 'fas fa-cogs',
      permission: 'COMPANY_CONFIG_VIEW',
      notifkey: null
    },
  ]

  // {
    //   name: 'Cash Advance',
    //   to: '/cashadvance',
    //   key: 'cashadvance',
    //   icon: 'fas fa-wallet',
    //   permission: 'USER_CASH_ADVANCE',
    //   notifkey: 'MENU_CASH_ADVANCE'
    // },
    // {
    //   name: 'Kasir Petty Cash',
    //   to: '/finance/kasir',
    //   icon: 'fas fa-cash-register',
    //   permission: 'PETTY_CASH_VIEW',
    //   notifkey: 'ADMIN_KASIR_PETTYCASH'
    // },
    // {  
    //   name: 'TopUp Petty Cash',
    //   to: '/pettycash',
    //   icon: 'fas fa-donate',
    //   permission: 'PETTY_CASH_TOPUP_VIEW',
    //   notifkey: 'ADMIN_TOPUP_PETTYCASH'
    // },
    
    // {
    //   name: 'Laporan Cuti',
    //   to: '/attendance/admin/report',
    //   icon: 'fas fa-user-clock',
    //   permission: 'TIME_OFF_REPORT_VIEW',
    //   notifkey: null
  // },
  
  // {
    //   name: 'Setting Kehadiran',
    //   to: '/attend/admin/setting',
    //   icon: 'fas fa-user-clock',
    //   permission: 'ATTENDANCE_SETTING',
    //   notifkey: null
    // }, 
  // {
    //   name: 'Informasi',
    //   heading: true,
    // }, 
    
    // {
    //   name: 'Kalender',
    //   to: '/information/calendar',
    //   icon: 'fas fa-calendar-alt',
    //   permission: 'USER_CALENDAR',
    //   notifkey: null
    // }, 
    // {
    //   name: 'Perusahaan PIC',
    //   to: '/company/managerial',
    //   icon: 'fas fa-network-wired',
    //   permission: 'SETTING_MANAGERIAL_VIEW',
    //   notifkey: null
    // },
    // {
    //   name: 'Kategori PIC',
    //   to: '/company/category',
    //   icon: 'fas fa-clipboard-list',
    //   permission: 'SETTING_CATEGORY_VIEW',
    //   notifkey: null
    // },
    // { hide dulu
    //   name: 'Petty Cash',
    //   to: '/company/pettycash',
    //   icon: 'fas fa-money-bill',
    //   permission: 'SETTING_PETTYCASH_VIEW',
    //   notifkey: null
    // },
    // {
    //   name: 'Bank Perusahaan',
    //   to: '/companybank',
    //   icon: 'fas fa-money-bill',
    //   permission: 'COMPANY_BANK_VIEW',
    //   notifkey: null
    // }
}
