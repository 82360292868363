var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "tnt-btn tnt-btn-prime",
      style: _vm.styleObject,
      attrs: { id: "btn-refresh" },
      on: {
        click: function($event) {
          return _vm.refresh($event)
        }
      }
    },
    [_vm._v("\n  " + _vm._s(_vm.btnText) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }