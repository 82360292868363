<template>
  <div class="d-inline-block">
    <button
      v-if="deferredPrompt"
      ref="addBtn"
      class="tnt-btn tnt-btn-prime rounded px-3 py-1"
      @click="clickCallback"
    >
      Install
    </button>
  </div>
</template>

<script>
export default {
  name: "AddToHomeScreen",
  data: () => ({
    deferredPrompt: null
  }),
  mounted() {
    this.captureEvent();
  },
  methods: {
    captureEvent() {
      window.addEventListener("beforeinstallprompt", e => {
        // -! Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
      });
    },
    clickCallback() {
      // Show the prompt
      this.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      this.deferredPrompt.userChoice.then(choiceResult => {
        if (choiceResult.outcome === "accepted") {
          // Call another function?
        }
        this.deferredPrompt = null;
      });
    }
  }
};
</script>
