var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass: "customsidebarwidth",
      style: _vm.getBgColor(),
      attrs: { id: "sidebar", "aria-label": "Main Navigation" }
    },
    [
      _vm._t("default", [
        _c(
          "div",
          { staticClass: "content-header" },
          [
            _c(
              "router-link",
              {
                staticClass: "font-w600 text-dual",
                staticStyle: { height: "100%" },
                attrs: { to: "/" }
              },
              [
                _c("img", {
                  staticStyle: { height: "70%", "margin-top": "10%" },
                  attrs: { src: require("../../assets/icons/logo_color.png") }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "base-layout-modifier",
                  {
                    staticClass: "d-lg-none ml-1",
                    attrs: {
                      size: "sm",
                      variant: "dual",
                      action: "sidebarClose"
                    }
                  },
                  [_c("i", { staticClass: "fa fa-fw fa-times" })]
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "simplebar",
          { staticClass: "js-sidebar-scroll", style: _vm.getBgColor() },
          [
            _c("div", { staticClass: "d-flex p-2 mb-2 box-aa" }, [
              _c(
                "div",
                { staticClass: "mr-2" },
                [
                  _c("b-avatar", {
                    attrs: {
                      "badge-variant": "primary",
                      src: _vm.usrPhoto,
                      size: "3rem"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "font-common" }, [
                _c("div", { staticClass: "font-weight-bold tnt-main-color" }, [
                  _c(
                    "span",
                    {
                      staticClass: "cursor-pointer",
                      on: {
                        click: function($event) {
                          return _vm.$router.push({ name: "Settings" })
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.userInfo.full_name))]
                  )
                ]),
                _vm._v(" "),
                _vm.userInfo.company
                  ? _c("div", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.userInfo.company.name) +
                          "\n          "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.userInfo.emp_division
                  ? _c("div", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.userInfo.emp_division.name +
                              " - " +
                              _vm.userInfo.emp_position.label
                          ) +
                          "\n          "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.userInfo.em_title
                  ? _c("div", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.userInfo.em_title) +
                          "\n          "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.emp_position_admin
                  ? _c("div", { staticClass: "tnt-main-color" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.emp_position_admin) +
                          "\n          "
                      )
                    ])
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex justify-content-center mb-2" },
              [
                _c("UpdateAppBtn", {
                  staticClass: "w-100 mx-4",
                  attrs: { alwaysShow: "false", btnText: "Update Aplikasi" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "content-side pt-2 box-aa" },
              [_c("base-navigation", { attrs: { nodes: _vm.navigation } })],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "text-right mt-2 mr-3 text-dark" }, [
              _c(
                "span",
                {
                  staticStyle: { cursor: "pointer" },
                  on: { click: _vm.toClear }
                },
                [_vm._v(" Clear Cache ")]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "text-right mt-2 mr-3 mb-3 text-dark" }, [
              _c(
                "span",
                {
                  staticStyle: { cursor: "pointer" },
                  on: { click: _vm.toChangelog }
                },
                [_vm._v(" v" + _vm._s(_vm.ver) + " ")]
              )
            ])
          ]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }