var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { class: _vm.classContainer },
    _vm._l(_vm.nodes, function(node, index) {
      return _c(
        "li",
        {
          key: "node-" + index,
          class: {
            "nav-main-item": !node.heading,
            open:
              node.sub && node.subActivePaths
                ? _vm.subIsActive(node.subActivePaths)
                : false
          }
        },
        [
          _vm.checkHeader(node.name)
            ? [
                _c("div", { staticClass: "nav-header-text" }, [
                  _c("span", {}, [
                    _vm._v(
                      "\n          " +
                        _vm._s(node.heading ? node.name : "") +
                        "\n        "
                    )
                  ])
                ])
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.getPermissions(node.permission)
            ? [
                !node.heading && !node.sub
                  ? _c(
                      "router-link",
                      {
                        staticClass: "nav-main-link",
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                          background: "none",
                          "text-decoration": "none",
                          color: "#ffffff"
                        },
                        attrs: { to: node.to || "#", id: "nav-" + index },
                        nativeOn: {
                          click: function($event) {
                            return _vm.linkClicked($event, index)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c("div", { staticClass: "nav-icon-custom" }, [
                              node.icon
                                ? _c("i", {
                                    class: "nav-main-link-icon " + node.icon,
                                    staticStyle: { color: "#ffffff" },
                                    attrs: { id: "nav-icon-" + index }
                                  })
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            node.name
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "nav-main-link-name",
                                    staticStyle: { "max-width": "150px" }
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(node.name) +
                                        "\n          "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        ),
                        _vm._v(" "),
                        _vm.$store.getters.sbNotifications
                          ? [
                              node.notifkey &&
                              _vm.$store.getters.sbNotifications[
                                node.notifkey
                              ] !== 0
                                ? _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "justify-content": "center",
                                          "align-items": "center",
                                          height: "20px",
                                          width: "20px",
                                          background: "#ea3e3a",
                                          "border-radius": "50%",
                                          border: "none",
                                          outline: "none"
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "#ffffff !important"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$store.getters
                                                    .sbNotifications[
                                                    node.notifkey
                                                  ]
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  : _vm._e()
              ]
            : !node.heading && node.sub
            ? _c(
                "a",
                {
                  staticClass: "nav-main-link nav-main-link-submenu",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.linkClicked($event, true)
                    }
                  }
                },
                [
                  node.icon
                    ? _c("i", { class: "nav-main-link-icon " + node.icon })
                    : _vm._e(),
                  _vm._v(" "),
                  node.name
                    ? _c("span", { staticClass: "nav-main-link-name" }, [
                        _vm._v(_vm._s(node.name))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  node.badge
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "nav-main-link-badge badge badge-pill badge-primary",
                          class: node["badge-variant"]
                            ? "badge-" + node["badge-variant"]
                            : "badge-primary"
                        },
                        [_vm._v(_vm._s(node.badge))]
                      )
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          node.sub
            ? _c("base-navigation", {
                attrs: { nodes: node.sub, "sub-menu": "" }
              })
            : _vm._e()
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }